import * as React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Gallery from '@browniebroke/gatsby-image-gallery';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';
import Page from '../components/Page';
import Box from '../components/Box';
import Title from '../components/Title';

// styles
const TitleWrapper = styled(Box).attrs({
  mt: '35px',
})``;

const GalleryWrapper = styled(Box).attrs({
  m: '30px',
})`
  & > div {
    & > div {
      position: relative;
      z-index: 0;
      cursor: pointer;
    }
  }
`;

const Content = styled.h2`
  font-family: 'Shadows Into Light', cursive;
  font-size: 30px;
`;

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query GalleryImages {
      images: allS3Object(filter: {Key: {glob: "content/gallery/*"}}) {
        nodes {
          Key
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  return data.images.nodes.map(node => node.localFile.childImageSharp);
};

const PhotosPage = () => {
  const images = useGallery();
  return (
    <Page>
      <TitleWrapper>
        <Title text={'Our Adventures'} />
      </TitleWrapper>
      <Box m={'0 auto'} maxWidth={'1000px'}>
        <GalleryWrapper>
          <Gallery images={images} />
        </GalleryWrapper>
      </Box>
    </Page>
  );
};

export default PhotosPage;
